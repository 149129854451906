<template>
  <van-form @submit="onSubmit">
    <van-field
      v-model.trim="username"
      :placeholder="$t('placeholder[0]')"
      :rules="[{ required: true }]"
    />
    <van-field
      v-model.trim="password"
      type="password"
      :placeholder="$t('placeholder[1]')"
      :rules="[{ required: true }]"
    />
    <div class="verification-wrap">
      <van-field
        v-model.trim="identifyCode"
        :placeholder="$t('placeholder[3]')"
        :rules="[{ required: true }]"
      />
      <div class="img-container" @click="getVerificationCode">
        <img
          alt="Verification"
          :src="verificationCodeSrc"
          style="height: 100%"
        />
      </div>
    </div>

    <!-- <div class="p-10 f">
      <router-link class="fs-14 c-999" to="/forget">
        {{ $t('forget') }}?
      </router-link>
    </div> -->
    <div style="margin: 16px">
      <van-button
        round
        block
        type="info"
        :loading="loginLoading"
        native-type="submit"
      >
        {{ $t("login") }}
      </van-button>
    </div>
  </van-form>
</template>
<script>
import md5 from "js-md5";
import Rsa from "@/utils/rsa";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      username: "",
      password: "",
      loginLoading: false,
      verificationCodeSrc: null,
      identifyId: null,
      identifyCode: "",
    };
  },

  computed: {
    ...mapGetters({
      publicKey: "publicKey",
    }),
  },
  created() {
    this.identifyId = ''
    this.getVerificationCode()
  },
  methods: {
    async getVerificationCode() {
      const params = {
        identifyId: this.identifyId,
      };
      const { code, msg, data } = await this.$api.identifyImage(params);
      if (code === 200 && data) {
        const { identifyImage, identifyId } = data;
        this.verificationCodeSrc = identifyImage;
        this.identifyId = identifyId;
      } else {
        this.$notify.error(msg);
      }
    },
    ...mapActions({
      setPublicKey: "setPublicKey",
    }),
    async onSubmit() {
      this.loginLoading = true;

      const loginAccount = this.username.trim();
      const passwd = this.password.trim();
      let newKey = this.publicKey;
      if (!this.publicKey) {
        await this.setPublicKey()
          .then((res) => {
            newKey = res;
          })
          .catch(() => {
            // console.log(error)
            this.loginLoading = false;
            this.$toast("get publick key fail!");
            return;
          });
      }
      const params = {
        loginAccount: Rsa.encrypt(loginAccount, newKey),
        password: md5(passwd),
        identifyCode: this.identifyCode,
        identifyId: this.identifyId,
      };
      this.$api
        .login(params)
        .then((res) => {
          // console.log(res.data)
          localStorage.setItem("userName", loginAccount);
          localStorage.setItem("passwd", passwd);
          localStorage.setItem("token", res.data);
          this.$api.findLoginInfo().then((res) => {
            localStorage.setItem("userInfo", JSON.stringify(res.data));
            this.$router.replace("/");
          });
        })
        .catch((err) => {
          this.loginLoading = false;
          this.getVerificationCode()
          this.$toast(err.msg || "Network abnormality, check network status");
        });
    },
  },
};
</script>

<style>
.verification-wrap {
  position: relative;
}
.img-container {
  position: absolute;
  right: 30px;
  top: 5px;
  height: 35px;
  margin-left: 10px;
  display: inline-block;
  vertical-align: middle;
}
</style>